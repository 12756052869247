<template>
  <div>
    <Loader :visible="isLoading" />
    <div :class="mobile ? 'pt-4' : ''" class="" v-if="!isLoading">
      <v-container fluid>
        <div :class="`tway-headline-3 ${desktop ? 'title-desktop' : 'title-mobile'}`">
          {{ desktop ? "Conoce lo último en Transformación" : "Lo último en Transformación Digital" }}
        </div>
      </v-container>
      <v-container fluid>
        <v-row v-if="mobile">
          <v-col cols="12">
            <div style="font-size: 20px" class="tway-headline-3 mb-3">Actualidad</div>
            <v-carousel hide-delimiters height="auto" v-model="carrouselIndex">
              <v-carousel-item v-for="(getAbstract, n) in getGATrends" :key="getAbstract.source">
                <horizontal-trend-post-card-getabstract
                  v-if="getAbstract.flag == 'SMALL' && getAbstract.sourceType == 'GETABSTRACT'"
                  :left="n % 2 !== 0"
                  class="mb-5"
                  :post="getAbstract"
                />
                <image-trend-post-card-getabstract
                  v-if="getAbstract.flag != 'SMALL' && getAbstract.sourceType == 'GETABSTRACT'"
                  class="mb-5"
                  :post="getAbstract"
                />
              </v-carousel-item>
            </v-carousel>
            <div class="numberDelimeter">{{ carrouselIndex + 1 }} de {{ getGATrends.length }}</div>
          </v-col>
          <v-col class="px-3" cols="12">
            <v-carousel hide-delimiters height="auto" v-model="carrouselIndex2">
              <v-carousel-item v-for="(current, n) in getStaticTrends.current" :key="current.id">
                <card
                  v-if="current.flag == 'SMALL' && current.sourceType != 'GETABSTRACT'"
                  :left="n % 2 !== 0"
                  class="mb-5"
                  :post="current"
                />
                <image-trend-post-card
                  v-if="current.flag != 'SMALL' && current.sourceType != 'GETABSTRACT'"
                  class="mb-5"
                  :post="current"
                />
              </v-carousel-item>
            </v-carousel>
            <div class="numberDelimeter">{{ carrouselIndex2 + 1 }} de {{ getStaticTrends.current.length }}</div>
          </v-col>
          <v-col class="px-3" :class="mobile ? 'mb-15' : ''" cols="12">
            <div style="font-size: 20px" class="tway-headline-3 mb-3">Opinión</div>
            <v-carousel hide-delimiters height="auto" v-model="carrouselIndex3">
              <v-carousel-item v-for="(current, n) in getStaticTrends.opinion" :key="current.id">
                <card
                  v-if="current.flag == 'SMALL' && current.sourceType != 'GETABSTRACT'"
                  :left="n % 2 !== 0"
                  class="mb-5"
                  :post="current"
                />
                <image-trend-post-card
                  v-if="current.flag != 'SMALL' && current.sourceType != 'GETABSTRACT'"
                  class="mb-5"
                  :post="current"
                />
              </v-carousel-item>
            </v-carousel>
            <div class="numberDelimeter">{{ carrouselIndex3 + 1 }} de {{ getStaticTrends.opinion.length }}</div>
          </v-col>
        </v-row>
        <v-row v-if="desktop">
          <v-col class="px-3" :class="mobile ? 'mb-15' : ''" cols="12">
            <div style="font-size: 20px" class="tway-headline-3 mb-3">Actualidad</div>
            <v-carousel hide-delimiters height="450px" v-model="carrouselIndexM">
              <v-carousel-item v-for="(asd, index) in new Array(getGATrends.length)" :key="index">
                <v-row class="d-flex align-center">
                  <v-col cols="4" v-for="(current, n) in modifiedGA" :key="current.source">
                    <image-trend-post-card-getabstract
                      v-if="current.flag != 'SMALL' && current.title != ''"
                      class="mb-5"
                      :post="current"
                      :style="n == 1 ? 'height:400px' : 'height:300px'"
                    />
                  </v-col>
                </v-row>
              </v-carousel-item>
            </v-carousel>
            <div class="numberDelimeter">{{ carrouselIndexM + 1 }} de {{ getGATrends.length }}</div>
          </v-col>
          <v-col class="px-3" :class="mobile ? 'mb-15' : ''" cols="12">
            <v-carousel hide-delimiters height="250px" v-model="carrouselIndexM2">
              <v-carousel-item v-for="(asd, index) in new Array(getStaticTrends.current.length)" :key="index">
                <v-row class="d-flex align-center">
                  <v-col cols="4" v-for="(current, n) in modifiedCurrent" :key="current.title">
                    <card
                      v-if="current.flag == 'SMALL' && current.title != ''"
                      :left="n % 2 !== 0"
                      class="mb-5"
                      :post="current"
                      :style="n == 1 ? 'height:200px' : ''"
                    />
                    <image-trend-post-card
                      v-if="current.flag != 'SMALL' && current.title != ''"
                      class="mb-5"
                      :post="current"
                      :style="n == 1 ? 'height:200px' : 'height:150px'"
                    />
                  </v-col>
                </v-row>
              </v-carousel-item>
            </v-carousel>
            <div class="numberDelimeter">{{ carrouselIndexM2 + 1 }} de {{ getStaticTrends.current.length }}</div>
          </v-col>
          <v-col class="px-3" :class="mobile ? 'mb-15' : ''" cols="12">
            <div style="font-size: 20px" class="tway-headline-3 mb-3">Opinión</div>
            <v-carousel hide-delimiters height="250px" v-model="carrouselIndexM3">
              <v-carousel-item v-for="(asd, index) in new Array(getStaticTrends.opinion.length)" :key="index">
                <v-row class="d-flex align-center">
                  <v-col cols="4" v-for="(current, n) in modifiedOpinion" :key="current.title">
                    <card
                      v-if="current.flag == 'SMALL' && current.title != ''"
                      :left="n % 2 !== 0"
                      class="mb-5"
                      :post="current"
                      :style="n == 1 ? 'height:200px' : ''"
                    />
                  </v-col>
                </v-row>
              </v-carousel-item>
            </v-carousel>
            <div class="numberDelimeter">{{ carrouselIndexM3 + 1 }} de {{ getStaticTrends.opinion.length }}</div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.numberDelimeter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.v-window {
  &-x-transition,
  &-x-reverse-transition,
  &-y-transition,
  &-y-reverse-transition {
    &-enter-active,
    &-leave-active {
      transition: 10ms cubic-bezier(0.25, 0.8, 0.5, 1) !important;
    }
  }
}
</style>

<script>
import HorizontalTrendPostCard from "@/components/trends/trendCard/HorizontalTrendPostCard";
import HorizontalTrendPostCardGetAbstract from "@/components/trends/trendCard/HorizontalTrendPostCardGetAbstract";
import ImageTrendPostCard from "@/components/trends/trendCard/ImageTrendPostCard";
import ImageTrendPostCardGetAbstract from "@/components/trends/trendCard/ImageTrendPostCardGetAbstract";
import Loader from "@/components/Loader";

import CompanyService from "@/services/companyService";
import { mapActions, mapGetters } from "vuex";

const TRENDS_STEP = 3;
export default {
  name: "Trends",

  components: {
    card: HorizontalTrendPostCard,
    "horizontal-trend-post-card-getabstract": HorizontalTrendPostCardGetAbstract,
    "image-trend-post-card-getabstract": ImageTrendPostCardGetAbstract,
    ImageTrendPostCard,
    Loader,
  },

  data() {
    return {
      badges: [
        "Transformación",
        "Era Digital",
        "Economía Digital",
        "Industrias 4.0",
        "Orquestación",
        "Mindset Digital",
        "Tecnologías",
      ],
      isLoading: true,
      carrouselIndex: 0,
      carrouselIndex2: 0,
      carrouselIndex3: 0,
      carrouselIndexM: 0,
      carrouselIndexM2: 0,
      carrouselIndexM3: 0,
      modifiedOpinion: [],
      modifiedCurrent: [],
      modifiedGA: [],
    };
  },

  async created() {
    let userSteps = this.getSteps;
    Promise.all([this.setGATrends(), this.setStaticTrends()]).then(() => {
      const arrOp = this.getStaticTrends.opinion;
      this.modifiedOpinion = [arrOp[arrOp.length - 1], arrOp[0], arrOp[1]];
      const arrCu = this.getStaticTrends.current;
      this.modifiedCurrent = [arrCu[arrCu.length - 1], arrCu[0], arrCu[1]];
      const arrGA = this.getGATrends;
      this.modifiedGA = [arrGA[arrGA.length - 1], arrGA[0], arrGA[1]];
      this.isLoading = false;
    });

    if (userSteps && userSteps[TRENDS_STEP] && !userSteps[TRENDS_STEP].active) {
      await this.activeTrends();
    }
  },

  methods: {
    ...mapActions("UserStore", ["SET_STEP_ACTIVE"]),
    ...mapActions("TrendStore", ["setGATrends", "setStaticTrends"]),
    async activeTrends() {
      await CompanyService.getCompanyProfile()
        .then(async (company) => {
          company.activity = { ...company.activity, trendsVisited: true };
          let companyPOST = {
            ...company,
            industrialSector: company.industrialSector ? company.industrialSector.id : null,
            industrialSectors: company.industrialSectors ? company.industrialSectors.map((is) => is.id) : null,
            dimensions: company.dimensions ? company.dimensions.map((d) => d.name) : null,
          };
          await CompanyService.updateCompanyProfile(companyPOST)
            .then(() => this.SET_STEP_ACTIVE(TRENDS_STEP))
            .catch((err) => this.$log.error(err));
        })
        .catch((err) => console.log(err));
    },
  },

  computed: {
    ...mapGetters("UserStore", ["getSteps"]),
    ...mapGetters("TrendStore", ["getStaticTrends", "getGATrends"]),

    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
  watch: {
    carrouselIndexM3(val) {
      const arr = this.getStaticTrends.opinion;
      if (this.carrouselIndexM3 == 0) {
        this.modifiedOpinion = [arr[arr.length - 1], arr[0], arr[1]];
      }
      if (this.carrouselIndexM3 !== 0) {
        this.modifiedOpinion = arr.slice(val - 1, val + 2);
      }
      if (this.carrouselIndexM3 == arr.length - 1) {
        this.modifiedOpinion = arr.slice(val - 2, val + 1);
      }
    },
    carrouselIndexM2(val) {
      const arr = this.getStaticTrends.current;
      if (this.carrouselIndexM2 == 0) {
        this.modifiedCurrent = [arr[arr.length - 1], arr[0], arr[1]];
      }
      if (this.carrouselIndexM2 !== 0) {
        this.modifiedCurrent = arr.slice(val - 1, val + 2);
      }
      if (this.carrouselIndexM2 == arr.length - 1) {
        this.modifiedCurrent = arr.slice(val - 2, val + 1);
      }
    },
    carrouselIndexM(val) {
      const arr = this.getGATrends;
      if (this.carrouselIndexM == 0) {
        this.modifiedGA = [arr[arr.length - 1], arr[0], arr[1]];
      }
      if (this.carrouselIndexM !== 0) {
        this.modifiedGA = arr.slice(val - 1, val + 2);
      }
      if (this.carrouselIndexM == arr.length - 1) {
        this.modifiedGA = arr.slice(val - 2, val + 1);
      }
    },
  },
};
</script>
